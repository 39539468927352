import { AxiosInstance, AxiosResponse } from "axios";

export interface EmailTemplateI {
  id?: string;
  name?: string;
  is_custom?: boolean;
  prompt?: string;
  attach_one_pager?: boolean;
  attachments?: AttachmentI[];
}

export interface AttachmentI {
  id: string;
  name: string;
}

export interface GetEmailTemplatesListResponseI {
  email_templates: EmailTemplateI[];
}

export interface CrateEmailTemplateResponseI {
  email_template: EmailTemplateI[];
}

export interface CreateEmailTemplateRequestParamsI {
  name: string;
  prompt: string;
  attach_one_pager: boolean;
}

export interface CreateEmailTemplateReponseI {
  email_template: EmailTemplateI;
}

export interface UpdateEmailTemplateRequestParamsI
  extends CreateEmailTemplateRequestParamsI {}

export interface CreateEmailDraftRequestParamsI {
  campaign_id: string;
  account_id: string;
  contact_id: string;
}

export interface CreateEmailDraftResponseI {
  email_id?: string;
  from_email?: string;
}

export interface GenerateAIEmailRequestParamsI {
  email_id: string;
}

export interface GenerateEmailExampleRequestParamsI {
  prompt: string;
  campaign_id: string;
  account_id: string;
  contact_id: string;
}

export interface GenerateEmailExampleResponseI {
  email_id: string;
}

export interface GetEmailGenerationStatusResponseI {
  is_complete?: boolean;
  created_at?: string;
  subject?: string;
  body?: string;
  attachments?: AttachmentI[];
}

export interface ValidateEmailsRequestParamsI {
  emails: string[];
}

export interface ValidateEmailsResponseI {
  valid_emails: string[];
  invalid_emails: string[];
}

export interface SendEmailWithAIRequestParamsI {
  to: string[];
  cc?: string[];
  bcc?: string[];
  max_follow_ups: number;
  follow_up_interval_days: number;
}

export interface SendEmailRequestParamsI extends SendEmailWithAIRequestParamsI {
  subject: string;
  body: string; //plain_text
  raw_body: string; //html_text
  attachments: string[];
}

export interface EmailApiI {
  getEmailTemplatesList: () => Promise<
    AxiosResponse<GetEmailTemplatesListResponseI>
  >;

  checkCampaignHasEmailAutomation: (
    campaignId: string
  ) => Promise<AxiosResponse>;

  createEmailTemplate: (
    params: CreateEmailTemplateRequestParamsI
  ) => Promise<AxiosResponse<CreateEmailTemplateReponseI>>;

  updateEmailTemplate: (
    templateId: string,
    params: UpdateEmailTemplateRequestParamsI
  ) => Promise<AxiosResponse>;

  deleteEmailTemplate: (templateId: string) => Promise<AxiosResponse>;

  createEmailDraft: (
    params: CreateEmailDraftRequestParamsI
  ) => Promise<AxiosResponse<CreateEmailDraftResponseI>>;

  generateEmailExample: (
    params: GenerateEmailExampleRequestParamsI
  ) => Promise<AxiosResponse<GenerateEmailExampleResponseI>>;

  generateAIEmail: (
    templateId: string,
    params: GenerateAIEmailRequestParamsI
  ) => Promise<AxiosResponse>;

  getEmailGenerationStatus: (
    emailId: string
  ) => Promise<AxiosResponse<GetEmailGenerationStatusResponseI>>;

  validateEmails: (
    params: ValidateEmailsRequestParamsI
  ) => Promise<AxiosResponse<ValidateEmailsResponseI>>;

  sendEmail: (
    emailId: string,
    params: SendEmailRequestParamsI
  ) => Promise<AxiosResponse>;

  // starts a backend process for the AI to send the email whenever it's done generting it with AI (user does not wait on the client for it to complete)
  sendEmailWithAI: (
    emailId: string,
    params: SendEmailWithAIRequestParamsI
  ) => Promise<AxiosResponse>;
}

export const EmailAPIEndpoints = (API: AxiosInstance): EmailApiI => ({
  getEmailTemplatesList: () => API.get(`/v1/email_template/list`),

  checkCampaignHasEmailAutomation: (campaignId) =>
    API.get(`/v1/campaign/${campaignId}/email_is_setup`),

  createEmailTemplate: (params) => API.post(`/v1/email_template`, params),

  updateEmailTemplate: (templateId, params) =>
    API.patch(`/v1/email_template/${templateId}`, params),

  deleteEmailTemplate: (templateId) =>
    API.delete(`/v1/email_template/${templateId}`),

  createEmailDraft: (params) => API.post(`/v1/email/create_draft`, params),

  generateAIEmail: (templateId, params) =>
    API.post(`/v1/email_template/${templateId}/generate`, params),

  getEmailGenerationStatus: (emailId) =>
    API.get(`/v1/email_template/${emailId}/generation_status`),

  generateEmailExample: (params) =>
    API.post(`/v1/email/generate_example`, params),

  validateEmails: (params) =>
    API.post(`/v1/email/confirm_email_address`, params),

  sendEmail: (emailId, params) => API.post(`/v1/email/${emailId}/send`, params),

  sendEmailWithAI: (emailId, params) =>
    API.post(`/v1/email/${emailId}/send_without_waiting`, params),
});
