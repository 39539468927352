import { FC } from "react";
import { toast } from "react-hot-toast";

import { ConfirmActionModal } from "shared/ui/modal/confirm-action";
import { getFullEntityName } from "shared/lib/helpers";
import { useSetUnsetContactAsPrimary } from "@/api/routes/account/mutations";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { CampaignI } from "@/interfaces/campaign";
import { AccountI } from "@/interfaces/accounts";

interface SetUnsetPrimaryLeadConfirmationModalPropsI {
  campaign?: CampaignI;
  account?: AccountI;
  contact?: ContactDetailI;
  onSuccess?: () => void;
}

export const SET_UNSET_PRIMARY_LEAD_CONFIRMATION_MODAL_ID =
  "set-primary-lead-confirmation-modal";

export const SetUnsetPrimaryLeadConfirmationModal: FC<
  SetUnsetPrimaryLeadConfirmationModalPropsI
> = ({ campaign, account, contact, onSuccess }) => {
  const { mutateAsync: setUnsetPrimaryLead } = useSetUnsetContactAsPrimary();

  const onConfirm = async () => {
    if (!campaign?.id || !account?.id || !contact?.id) {
      return toast.error(
        "Either working contact is not selected, or campaign / account are not specified, please try again."
      );
    }

    await setUnsetPrimaryLead(
      {
        campaignId: campaign.id,
        accountId: account.id,
        contactId: contact.id,
        isPrimary: !contact.is_primary_lead,
      },
      {
        onSuccess: () => {
          onSuccess?.();
        },
        onError: () => {
          return toast.error("Failed to set/unset primary lead");
        },
      }
    );
  };

  if (!contact) {
    return null;
  }

  const { is_primary_lead: isPrimary } = contact;
  const setUnsetLabel = isPrimary ? "Unset" : "Set";

  return (
    <ConfirmActionModal
      id={SET_UNSET_PRIMARY_LEAD_CONFIRMATION_MODAL_ID}
      title={`${setUnsetLabel} as Primary Lead`}
      description={`Are you sure you want to ${setUnsetLabel.toLocaleLowerCase()} ${getFullEntityName(
        contact
      )} as primary lead?`}
      confirmButtonText="Confirm"
      onConfirm={onConfirm}
    />
  );
};
