import { DATE_FROMAT_ONLY_TIME_WITH_TZ } from "shared/lib/constants/time";
import { TIMEZONES } from "shared/lib/constants/timezones";
import { clsxMerge } from "shared/lib/helpers";
import { useLiveTime } from "shared/lib/hooks";
import { CurrentCallerBadge } from "./current-caller-badge";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const LeadAdditionalInfo = () => {
  const { contact } = useInCallContext();
  const callStatus = useDialerCallStatus();
  const { startAt } = useInCallContext();

  const isConnected = callStatus === DIALER_CALL_STATUS.DURING && !!startAt;
  const isEnded = callStatus === DIALER_CALL_STATUS.AFTER;

  const currentTime = useLiveTime({
    dateFormat: "",
    hourFormat: DATE_FROMAT_ONLY_TIME_WITH_TZ,
    timezone: contact?.timezone || TIMEZONES[0].tzCode,
  });

  return (
    <div
      className={clsxMerge(
        "flex flex-col items-end justify-center",
        "text-white",
        "p-3"
      )}
    >
      {isConnected && (
        <span className="typography-header-8-bold">Connected to lead</span>
      )}

      {isEnded && <span className="typography-header-8-bold">Call ended</span>}

      <span className={clsxMerge("typography-body-5-medium", "mb-2")}>
        Local time for contact {currentTime}
      </span>

      <CurrentCallerBadge />
    </div>
  );
};
