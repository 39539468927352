import { AccountDetailsStatusI } from "@/api/routes/account/interfaces";
import {
  ACCOUNT_DISPOSITION_STATUS_PILL_PARAMS,
  ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS,
} from "@/modules/pipeline/account-details/sidebar/sections/status-section/constants";
import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";

export const getAccountDispositionStatusParams = (
  accountStatus?: AccountDetailsStatusI
) => {
  if (!accountStatus?.status) {
    return {
      style: "",
      label: "",
    };
  }

  if (
    accountStatus.status === ACCOUNT_DISPOSITION_STATUSES.FEEDBACK_RECEIVED &&
    accountStatus.meeting_status
  ) {
    return ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS[
      accountStatus.meeting_status
    ];
  }

  return ACCOUNT_DISPOSITION_STATUS_PILL_PARAMS[accountStatus.status];
};
