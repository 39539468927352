import { useCallback, useMemo } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

import { BaseControlButton } from "@/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { ACCOUNT_SEND_EMAIL_MODAL_ID } from "@/modals/account-send-email-modal";

import { ContactDetailI } from "shared/lib/interfaces/account";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import ButtonMenu from "shared/ui/button-menu";
import { useCheckCampaignHasEmailAutomation } from "@/components/modules/email-templates-sidebar/queries";
import { useEmailTemplatesSidebarContext } from "@/components/modules/email-templates-sidebar/context";

export const EmailButton = () => {
  const { campaign } = useAccountDetailsContext();
  const { setFocusedContact, contacts } = useAccountDetailsContext();

  const { onOpen: onOpenEmailTemplatesSidebar } =
    useEmailTemplatesSidebarContext();

  const { data: checkIsCampaignEmailSetupData } =
    useCheckCampaignHasEmailAutomation(campaign?.id);

  const isEmailAutomationSetup = useMemo(
    () => checkIsCampaignEmailSetupData?.status === 200,
    [checkIsCampaignEmailSetupData]
  );

  // if the campaign has email automation setup, the EmailButton opens the email templates sidebar flow.
  // else, it presents the original email flow using the modal
  const handleEmailClick = useCallback(
    (contact: ContactDetailI) => {
      setFocusedContact(contact);

      setTimeout(() => {
        if (isEmailAutomationSetup) {
          onOpenEmailTemplatesSidebar();
        } else {
          modalHelpers.trigger(ACCOUNT_SEND_EMAIL_MODAL_ID);
        }
      }, 100);
    },
    [isEmailAutomationSetup]
  );

  const items = useMemo(
    () =>
      contacts?.map((contact) => {
        let itemLabel = "Contact without email";
        const firstOrLastName = contact.first_name || contact.last_name;
        const { email } = contact;

        if (firstOrLastName) {
          itemLabel = email ? `${firstOrLastName} - ${email}` : firstOrLastName;
        }

        return (
          <ButtonMenuItem
            className="text-right"
            text={itemLabel}
            icon={<EnvelopeIcon className="w-4" />}
            onClick={() => handleEmailClick(contact)}
          />
        );
      }) || [],
    [contacts, isEmailAutomationSetup]
  );

  if (contacts?.length === 1) {
    return (
      <BaseControlButton onClick={() => handleEmailClick(contacts[0])}>
        <EnvelopeIcon className="mb-1 w-5" />
        <span>Email</span>
      </BaseControlButton>
    );
  }

  return (
    <ButtonMenu
      items={items}
      className="p-0"
      position="end"
      containerClassName="dropdown-top"
      contentClassName="w-max max-w-[300px] mt-0 mb-2"
      renderTrigger={() => (
        <BaseControlButton>
          <EnvelopeIcon className="mb-1 w-5" />
          <span>Email</span>
        </BaseControlButton>
      )}
    />
  );
};
