export const ACCOUNT_DISQUALIFY_DISPOSITIONS = {
  ACCOUNT_NO_VIABLE_CONTACTS: "account_no_viable_contacts",
  ACCOUNT_NOT_IN_ICP: "account_not_in_icp",
  ACCOUNT_BAD_DATA: "account_bad_data",
  ACCOUNT_DO_NOT_CALL: "account_do_not_call",
};

export const CONTACT_DISQUALIFY_DISPOSITIONS = {
  CONTACT_WRONG_TITLE: "contact_wrong_title",
  CONTACT_DO_NOT_CALL: "contact_do_not_call",
  CONTACT_BAD_DATA: "contact_bad_data",
};

export const DISQUALIFY_DISPOSITIONS = {
  ...ACCOUNT_DISQUALIFY_DISPOSITIONS,
  ...CONTACT_DISQUALIFY_DISPOSITIONS,
} as const;

export const DISQUALIFY_DISPOSITION_LABELS = {
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_NO_VIABLE_CONTACTS]: "No Viable Contacts",
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_NOT_IN_ICP]:
    "Does not meet Qualification Criteria",
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_BAD_DATA]: "Bad Data",
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_DO_NOT_CALL]: "Do Not Call",

  [DISQUALIFY_DISPOSITIONS.CONTACT_WRONG_TITLE]:
    "Prospect Title does not match customer ICP",
  [DISQUALIFY_DISPOSITIONS.CONTACT_DO_NOT_CALL]: "Do Not Call",
  [DISQUALIFY_DISPOSITIONS.CONTACT_BAD_DATA]: "Bad Data",
};
