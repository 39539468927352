import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import _uniq from "lodash/uniq";

import { useEffectOnce, useLongPolling } from "shared/lib/hooks";
import { MessageInput } from "shared/ui/messages/message-input";
import {
  InfiniteScroll,
  SCROLL_DIRECTIONS,
} from "@/components/base/infinite-scroll";
import { LoadingComponent } from "@/components/modules/loading";
import AccountMessagesEmptyState from "./empty-state";
import { useWSMessaging } from "@/hooks/use-ws-messaging";
import { CHATS_ONLINE_STATUS_REFRESH_INTERVAL } from "shared/lib/constants/chat";
import { UserIsOnlineMapI } from "shared/lib/interfaces/chat";
import { HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID } from "@/constants/element-ids";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { glencocoClientAPI } from "@/api/glencoco";
import { buildUserIsOnlineMap } from "shared/lib/helpers/chat";
import { MessagesList } from "@/modules/pipeline/account-details/primary-content/sections/messages-section/messages-list";
import { useNetworkStatus } from "@/hooks/use-network-status";
import { messagingSocketActions } from "lib/websockets/messaging/socket-actions";

export const MESSAGES_SCROLL_CONTAINER_ID = "messages-scroll-container-id";

export const AccountMessagesSection = () => {
  const { accountStatus, accountExecutiveData } = useAccountDetailsContext();
  const accountUserDispositionId = accountStatus?.aud_id;

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [usersOnlineMap, setUsersOnlineMap] = useState<UserIsOnlineMapI>({});
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isOnline } = useNetworkStatus();

  const {
    messages,
    chatMembersMap,
    enterChat,
    isConnected: isChatConnected,
    fetchOlderMessages,
    isLoading,
    isMore,
  } = useWSMessaging({
    accountUserDispositionId: accountUserDispositionId as string,
  });

  const fetchOnlineStatuses = async (userIds?: Array<string>) => {
    if (userIds && userIds?.length > 0) {
      const API = glencocoClientAPI();

      const resp = await API.getChatInboxOnlineStatuses(userIds).catch(() => ({
        status: null,
        data: null,
      }));

      if (resp.status === 200) {
        setUsersOnlineMap(buildUserIsOnlineMap(resp?.data?.online_statuses));
      } else {
        console.log("Failed to get user online statuses.");
      }
    }
  };

  const handleLoadMoreData = async () => {
    const currentScrollPositionFromBottom = scrollRef.current
      ? scrollRef.current.scrollHeight
      : 100;

    const didLoadMoreDAta = await fetchOlderMessages();

    if (didLoadMoreDAta) {
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTop =
            scrollRef.current.scrollHeight - currentScrollPositionFromBottom;
        }
      }, 10);
    }
  };

  useEffectOnce(() => {
    (async () => {
      await fetchOlderMessages();

      scrollToBottom(); //scroll to bottom of chat
      enterChat();
      setIsInitialized(true);
    })();
  });

  useEffect(() => {
    scrollToBottomOfPage();
  }, [isInitialized]);

  const scrollToBottomOfPage = () => {
    const pageContainer = document.getElementById(
      HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID
    );

    if (pageContainer) {
      pageContainer.scrollTop = pageContainer.scrollHeight;
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleSubmitMessage = (
    messageText: string,
    handleCleanUp: () => void
  ) => {
    messagingSocketActions.sendMessage(messageText);
    handleCleanUp();
  };

  useEffect(() => {
    if (messages) {
      scrollToBottom();
    }
  }, [messages]);

  const handleFetchingOnlineStatuses = () => {
    const memberIds = _uniq(Object.keys(chatMembersMap).filter(Boolean));
    fetchOnlineStatuses(memberIds);
  };

  useEffect(() => {
    handleFetchingOnlineStatuses();
  }, [chatMembersMap]);

  useLongPolling(
    handleFetchingOnlineStatuses,
    CHATS_ONLINE_STATUS_REFRESH_INTERVAL,
    [chatMembersMap]
  );

  return (
    <div className="rounded-xl border bg-white p-5">
      <InfiniteScroll
        isLoading={isLoading}
        isMore={isMore}
        onMore={handleLoadMoreData}
        customScrollElementId={MESSAGES_SCROLL_CONTAINER_ID}
        scrollDirection={SCROLL_DIRECTIONS.UP}
        isDefaultLoadingComponentHidden
      >
        <div
          ref={scrollRef}
          id={MESSAGES_SCROLL_CONTAINER_ID}
          className={clsx(
            "relative mb-2 flex h-[500px] flex-col justify-between overflow-auto",
            {
              "opacity-0": !isInitialized,
              "opacity-100": isInitialized,
            }
          )}
        >
          <>
            {isLoading && <LoadingComponent className="absolute w-full" />}

            {!!messages?.length && !isMore && (
              <p className="b-typography-body3 text-center text-black/40">
                You've reached the beginning of this chat.
              </p>
            )}

            {/* Empty element so `flex justify-between` can work to make the messages start at the botom if above two elements aren't present */}
            <span />
          </>

          <div className="mt-3 flex flex-col place-content-end">
            {messages?.length ? (
              <MessagesList
                messages={messages}
                isLoading={isLoading}
                userOnlineMap={usersOnlineMap}
              />
            ) : (
              <AccountMessagesEmptyState
                accountExecutive={accountExecutiveData}
              />
            )}
          </div>
        </div>
      </InfiniteScroll>

      <div className="divider" />

      <>
        {isChatConnected && isOnline ? (
          <MessageInput onSubmit={handleSubmitMessage} />
        ) : (
          <div className="btn loading btn-ghost btn-xs pointer-events-none">
            Connecting to chat...
          </div>
        )}
      </>
    </div>
  );
};
