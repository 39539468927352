import { AccountI, ContactDetailI } from "@/interfaces/accounts";
import { FC, useMemo, useRef } from "react";
import { getFullEntityName } from "shared/lib/helpers";
import { Modal } from "shared/ui";
import * as Yup from "yup";

import { Dropdown } from "shared/ui";
import { DropdownOptionI } from "shared/ui/user-input/dropdown";
import { Field, Form, Formik, FormikProps } from "formik";
import CalendarForm, {
  calendarFormValuesToISODate,
} from "shared/ui/calendar-form";
import { glencocoClientAPI } from "@/api/glencoco";
import { CampaignI } from "@/interfaces/campaign";
import toast from "react-hot-toast";

export const MOVE_TO_NURTURE_MODAL_ID = "move-to-nurture-modal";

interface MoveToNurtureModalI {
  contacts?: ContactDetailI[];
  campaign?: CampaignI;
  account?: AccountI;
  onSuccess?: () => void;
}

interface ContactsFormI {
  contactId?: string;
}

const EnhancedDropdown = Dropdown(Field);

const FormValidationSchema = Yup.object().shape({
  contactId: Yup.string().required("Please select contact"),
});

const MoveToNurtureModalContent = ({
  campaign,
  account,
  contacts,
  onSuccess,
  onClose,
}: { onClose?: () => void } & MoveToNurtureModalI) => {
  const contactsFormRef = useRef<FormikProps<ContactsFormI>>(null);

  const contactsOption: DropdownOptionI[] =
    contacts?.map((c) => ({
      label: getFullEntityName(c),
      value: c.id,
    })) || [];

  const formInitialValues: ContactsFormI = useMemo(
    () => ({ contactId: undefined }),
    []
  );

  const handleCalendarFormSubmit = async (
    date: string,
    time: string,
    timezone: string,
    notes: string
  ) => {
    await contactsFormRef.current?.submitForm();

    if (!contactsFormRef.current?.isValid) return;

    const contactId = contactsFormRef.current?.values?.contactId;
    const nextTouchTime = calendarFormValuesToISODate(date, time, timezone);

    console.log(
      `handleCalendarFormSubmit values`,
      contactId,
      nextTouchTime,
      notes
    );

    const API = glencocoClientAPI();

    const Resp = await API.moveAccountToNurture(
      campaign?.id as string,
      account?.id as string,
      { contact_id: contactId as string, next_touch_time: nextTouchTime, notes }
    ).catch((e) => e);

    if (Resp.status !== 200) {
      toast.error("Failed to update an account");
      return;
    }

    onSuccess?.();
    onClose?.();
  };

  return (
    <>
      <Formik
        innerRef={contactsFormRef}
        enableReinitialize
        validationSchema={FormValidationSchema}
        initialValues={formInitialValues}
        onSubmit={() => {}}
      >
        {({ errors, touched }) => (
          <Form>
            <EnhancedDropdown
              name="contactId"
              label="Choose lead"
              placeholder="Select"
              options={contactsOption}
              errors={errors.contactId}
              touched={touched.contactId}
              inputProps={{
                onFocus: () => {
                  contactsFormRef.current?.setTouched({});
                },
              }}
            />
          </Form>
        )}
      </Formik>

      <CalendarForm
        bookingDeadlineInMonths={6}
        cancelButton={{
          type: "button",
          text: "Cancel",
          onClick: onClose,
        }}
        successButton={{
          type: "button",
          text: "Save",
        }}
        onFormSubmit={handleCalendarFormSubmit}
        isLoading={false}
        isNotes
        isNotesRequired
      />
    </>
  );
};

export const MoveToNurtureModal: FC<MoveToNurtureModalI> = (props) => {
  return (
    <Modal
      id={MOVE_TO_NURTURE_MODAL_ID}
      showCloseButton
      unmountOnClose
      title="Move to nurture"
      description="Specify date and time for when you intend to follow up with this lead"
    >
      {({ handleClose }) => {
        return <MoveToNurtureModalContent onClose={handleClose} {...props} />;
      }}
    </Modal>
  );
};
