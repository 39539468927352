import { useQueryState } from "nuqs";
import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "./constants";
import { useEffect } from "react";
import { WIDGETS } from "@/constants/widgets";
import { widgets } from ".";
import wait from "wait";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";

export const useWidgetsSearchQueryParams = () => {
  const [widgetType] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE
  );
  const [campaignId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID
  );
  const [accountId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID
  );

  useEffect(() => {
    (async () => {
      await wait(300);

      switch (widgetType) {
        case WIDGETS.MAXED_DIALER:
          dd.rum.log(`${LOG_CATEGORIES.WIDGET} - triggered`, {
            data: {
              filePath: `/shared/widgets/use-widgets-search-query-params.ts`,
            },
          });

          widgets.open({
            id: WIDGETS.MAXED_DIALER,
            config: {
              campaignId: campaignId as string,
              accountId: accountId as string,
            },
          });
          break;
      }
    })();
  }, [widgetType]);
};
