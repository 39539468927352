import { FC, ReactElement, useMemo } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { NextSeo } from "next-seo";
import { Toaster } from "react-hot-toast";
import _merge from "lodash/merge";

import { seo } from "lib/seo";
import { AuthProvider } from "lib/auth";

import { GlobalContextProvider } from "@/context/global";
import PrimaryNavigationV3 from "@/components/primary-navigation/v3";

import { CookieMessageNotifier } from "@/helpers/cookies";
import CustomMeetingsToaster from "components/shared/custom-meetings-toaster";
import { RedirectsProvider } from "lib/auth/redirects-provider";
import { IntercomComponent } from "@/helpers/intercom";
import { AppLayoutPagePropsI } from "@/interfaces/layout";
import { AppLayoutProvider, useAppLayoutContext } from "@/context/app-layout";
import { WidgetsProvider } from "@/components/shared/widgets";
import { DialerGlobalProvider } from "@/context/dialer-global";
import { queryClient } from "@/api/query-client";
import { LocalStorageProvider } from "@/context/local-storage";
import { ApiClientProvider } from "@/context/api-client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

interface AppLayoutPropsI {
  layoutProps: AppLayoutPagePropsI;
  children: ReactElement | ReactElement[];
}

type ChildrenWithPageLayoutPropsI = AppLayoutPropsI;

const ChildrenWithPageLayoutProps: FC<ChildrenWithPageLayoutPropsI> = ({
  layoutProps,
  children,
}) => {
  const { appLayoutPageProps: userDefinedLayoutProps } = useAppLayoutContext();

  const {
    navTitle,
    isCurrentTimeVisible,
    isBackgroundVisible,
    contentContainerClassName,
    pageContainerClassName,
    headerClassName,
  } = useMemo(
    () => _merge(layoutProps, userDefinedLayoutProps),
    [layoutProps, userDefinedLayoutProps]
  );

  return (
    <PrimaryNavigationV3
      title={navTitle}
      isCurrentTimeVisible={isCurrentTimeVisible}
      isBackgroundVisible={isBackgroundVisible}
      contentContainerClassName={contentContainerClassName}
      pageContainerClassName={pageContainerClassName}
      headerClassName={headerClassName}
    >
      {children}
    </PrimaryNavigationV3>
  );
};

const AppLayout: FC<AppLayoutPropsI> = ({ children, layoutProps }) => {
  const meta = layoutProps.meta || {};

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <GlobalContextProvider>
          <RedirectsProvider>
            <LocalStorageProvider>
              <ApiClientProvider>
                <NextSeo {...seo(meta)} />

                <IntercomComponent />

                <DialerGlobalProvider>
                  <WidgetsProvider>
                    <main data-theme="glencoco">
                      <Toaster
                        containerStyle={{
                          top: 80,
                        }}
                        position="top-center"
                      />

                      <CustomMeetingsToaster />

                      <AppLayoutProvider>
                        <ChildrenWithPageLayoutProps layoutProps={layoutProps}>
                          {children}
                        </ChildrenWithPageLayoutProps>
                      </AppLayoutProvider>

                      <CookieMessageNotifier />
                    </main>
                  </WidgetsProvider>
                </DialerGlobalProvider>
              </ApiClientProvider>
            </LocalStorageProvider>
          </RedirectsProvider>
        </GlobalContextProvider>
      </AuthProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppLayout;
