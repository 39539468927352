import { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  BulkDisqualifyContactsFromListRequestI,
  BulkMarkSeenContactsFromListRequestI,
  BulkRemoveContactsFromListRequestI,
  BulkUpdateListsRequestI,
  ContactToListI,
  CreateOrUpdateListRequestParamsI,
  GetListResponseI,
  GetListsRequestFilterParamsI,
} from "@/api/routes/list";
import { pluralizeNoun } from "shared/lib/helpers";
import { useDebouncedValue } from "shared/lib/hooks/use-debounced-value";
import { ListTableSortingStateI } from "./workspace/table/interfaces";
import { useApiClient } from "@/context/api-client";
import { ListSubsectionI } from "./interfaces";
import {
  selectorNormalizedFilters,
  selectorSyncContactsByAccountId,
} from "./selectors";

import { produce } from "immer";
import { AxiosResponse } from "axios";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";

export const LISTS_QUERY_KEY = "lists";
export const SEARCH_CONTACTS_QUERY_KEY = "contacts";

// Get all lists
export const useFetchLists = () => {
  const api = useApiClient();

  const listsApi = useQuery({
    queryKey: [LISTS_QUERY_KEY],
    queryFn: api.getLists,

    /**
     * Caching lists for 10 seconds. If any other mutation changes them, revalidation
     * is triggered and request will be sent disregarding the staleTime.
     */
    staleTime: 10_000,
  });

  useEffect(() => {
    if (listsApi.isError) {
      toast.error("Failed to load your lists, please try to reload the page.");
    }
  }, [listsApi.isError]);

  return listsApi;
};

export type FetchListsApiI = ReturnType<typeof useFetchLists>;

// List CRUD
export const useCreateList = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: CreateOrUpdateListRequestParamsI) =>
      api.createList(parameters),
    onSuccess: (_, { name }) => {
      toast.success(`List '${name}' has been created!`);

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to create new list."),
  });
};

export const useUpdateLists = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: BulkUpdateListsRequestI) =>
      api.bulkUpdateLists(parameters),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [LISTS_QUERY_KEY] }),
    onError: () => toast.error("Failed to update lists."),
  });
};

export const getListDetailsQueryKey = (listId: string) => [
  LISTS_QUERY_KEY,
  listId,
];

export const useFetchListDetails = (listId: string, isSearchMode = false) => {
  const api = useApiClient();

  const listDetailsApi = useQuery({
    enabled: !!listId?.trim() && !isSearchMode,
    queryKey: getListDetailsQueryKey(listId),
    queryFn: () => api.getListDetails(listId),
  });

  useEffect(() => {
    if (listDetailsApi.isError) {
      toast.error("Failed to load list details.");
    }
  }, [listDetailsApi.isError]);

  return listDetailsApi;
};

export const useUpdateList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: CreateOrUpdateListRequestParamsI) =>
      api.updateList(listId, parameters),
    onSuccess: () => {
      toast.success("List details have been updated!");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to update this list."),
  });
};

export const useDeleteList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: () => api.deleteList(listId),
    onSuccess: () => {
      toast.success("List has been removed.");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to remove this list."),
  });
};

interface GetListContactsQueryKeyConfigI {
  sortParams?: ListTableSortingStateI;
  filters?: GetListsRequestFilterParamsI;
}

export const getListContactsQueryKey = (
  listId: string,
  config?: GetListContactsQueryKeyConfigI
) => {
  const { sortParams, filters } = config || {};

  const baseKey: any[] = [
    LISTS_QUERY_KEY,
    listId,
    "contacts",
    sortParams,
    filters,
  ].filter((k) => !!k);

  return baseKey;
};

interface FetchListContactsParamsI {
  listId: string;
  sortParams: ListTableSortingStateI | undefined;
  filters: GetListsRequestFilterParamsI | undefined;
  isNurtureList?: boolean;
  isSystemList?: boolean;
}

// Contacts queries and mutations
export const useFetchListContacts = ({
  listId,
  sortParams,
  filters,
  isNurtureList,
  isSystemList,
}: FetchListContactsParamsI) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const api = useApiClient();

  const normalizedFilters = useMemo(
    () => selectorNormalizedFilters(filters, isNurtureList, isSystemList),
    [filters, isNurtureList, isSystemList]
  );

  const listsFetcher = api.getListContacts;

  const isEnabled = !!listId?.trim() && !!sortParams && !!filters;

  const listContactsApi = useInfiniteQuery({
    enabled: isEnabled,
    queryKey: getListContactsQueryKey(listId, {
      sortParams,
      filters: normalizedFilters,
    }),
    queryFn: ({ pageParam }) =>
      listsFetcher(listId, {
        next_token: pageParam,
        ...(sortParams || {}),
        ...(normalizedFilters || {}),
      }),
    initialPageParam: undefined as string | null | undefined,
    refetchOnWindowFocus: !isGlobalDialer, // default: true

    getNextPageParam: (lastPage) => lastPage.data?.next_token,
  });

  useEffect(() => {
    if (listContactsApi.isError) {
      toast.error(
        "Failed to load portion of contacts. Please try to reload the page."
      );
    }
  }, [listContactsApi.isError]);

  return {
    ...listContactsApi,
    refetch: () => {
      if (isEnabled) {
        /**
         * As query.refetch() DOESN'T respect enabled flag on query AND it's used
         * inside use-dialer-list-queue-table-data-extension.ts, in a state
         * right after list rendering, it's causing an extra unneeded API call.
         *
         * Modifying the refetch() to check if the query is enabled before calling
         * it.
         *
         * See more: https://github.com/TanStack/query/issues/1196#issuecomment-2118489049
         */
        return listContactsApi.refetch();
      }

      return Promise.resolve({}) as ReturnType<
        (typeof listContactsApi)["refetch"]
      >;
    },
  };
};

/**
 * Custom hook to update contacts by account ID.
 *
 * This hook provides a mutation that fetches contacts for a specific account
 * from a campaign list and updates the local React Query cache with the new
 * or modified contact data. The mutation uses `useApiClient` to make the API call
 * and `useQueryClient` to update the cached data.
 *
 * @returns {UseMutationResult} - A mutation result object from `useMutation`.
 */
export const useUpdateContactsByAccountId = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      /**
       * We need to use keys to update cached list contacts
       */
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      reactQueryKeys,
      request: { campaignId, listId, accountId, searchQueryParams },
    }: {
      reactQueryKeys: FetchListContactsParamsI;
      request: {
        campaignId: string;
        listId: string;
        accountId: string;
        searchQueryParams: {
          list_subsection: ListSubsectionI;
        };
      };
    }) =>
      api.getListContactsByAccountId(
        campaignId,
        listId,
        accountId,
        searchQueryParams
      ),
    /**
     * Updates the local cache with the fetched contacts after a successful mutation.
     *
     * This function is triggered when the API call (mutation) is successful. It:
     * 1. Retrieves the `contacts` from the API response.
     * 2. Normalizes the filters (if any) to ensure they can be used for caching purposes.
     * 3. Uses `queryClient.setQueryData` to update the cached list of contacts for the specified list.
     * 4. It modifies the cached contacts using `produce` to ensure that any new or updated contacts
     *    from the API response are merged into the correct pages of contacts in the cache.
     * 5. Contacts are updated by:
     *    - Syncing the current page's contacts with the contacts from the response using `selectorSyncContactsByAccountId`.
     *    - Keeping track of contacts that have not yet been synced (remaining contacts).
     * 6. If there are still unsynced contacts after processing all pages, they are prepended to the first page of contacts.
     **/
    onSuccess: (resp, { reactQueryKeys, request }) => {
      const accountContacts = resp.data?.contacts;

      queryClient.setQueryData<
        InfiniteData<AxiosResponse<GetListResponseI, any>>
      >(
        getListContactsQueryKey(reactQueryKeys.listId, {
          sortParams: reactQueryKeys.sortParams,
          filters: selectorNormalizedFilters(
            reactQueryKeys.filters,
            reactQueryKeys.isNurtureList,
            reactQueryKeys.isSystemList
          ),
        }),
        (oldData) => {
          if (!oldData) return oldData;

          let accountContactsToSync = accountContacts;

          const newData = produce(oldData, (draft) => {
            draft.pages.forEach((page) => {
              const [syncedContacts, remainingContactsToSync] =
                selectorSyncContactsByAccountId(
                  page.data.contacts || [],
                  accountContactsToSync,
                  request.accountId
                );

              accountContactsToSync = remainingContactsToSync;
              page.data.contacts = syncedContacts;
            });

            if (accountContactsToSync.length) {
              draft.pages[0].data.contacts.unshift(...accountContactsToSync);
            }

            return draft;
          });

          return newData as InfiniteData<AxiosResponse<GetListResponseI, any>>;
        }
      );
    },
    onError: () => {
      // Do nothing
      // Errors are logged by default into dd
    },
  });
};

export const useBulkAddContactsToList = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      contacts,
      list_ids,
    }: {
      contacts: ContactToListI[];
      list_ids: string[];
    }) =>
      api.bulkAddContactsToListRequest({
        contacts,
        list_ids,
      }),
    onSuccess: (_, { contacts: { length }, list_ids: listIds }) => {
      toast.success(
        `${
          length > 1 ? "Contacts have" : "Contact has"
        } been added to the specified ${pluralizeNoun("list", length)}`
      );

      listIds.forEach((listId) => {
        queryClient.invalidateQueries({
          queryKey: getListContactsQueryKey(listId),
        });
      });
    },
    onError: (_, { contacts: { length } }) => {
      toast.error(
        `Failed to add ${pluralizeNoun(
          "contact",
          length
        )} to the specified ${pluralizeNoun("list", length)}`
      );
    },
  });
};

export const useBulkRemoveContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ membership_ids }: BulkRemoveContactsFromListRequestI) =>
      api.bulkRemoveContactsFromListRequest(listId, {
        membership_ids,
      }),
    onSuccess: () => {
      toast.success("Contacts have been removed from the list.");

      queryClient.invalidateQueries({
        queryKey: getListContactsQueryKey(listId),
      });
    },
    onError: () => toast.error("Failed to remove contacts from this list."),
  });
};

export const useBulkMarkSeenContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ membership_ids }: BulkMarkSeenContactsFromListRequestI) =>
      api.bulkMarkSeenContactsFromListRequest(listId, {
        membership_ids,
      }),
    onSuccess: () => {
      toast.success("Contacts have been marked as seen.");

      queryClient.invalidateQueries({
        queryKey: getListContactsQueryKey(listId),
      });
    },
    onError: () => toast.error("Failed to mark contacts as seen."),
  });
};

export const useBulkDisqualifyContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      membership_ids,
      reason,
    }: BulkDisqualifyContactsFromListRequestI) =>
      api.bulkDisqualifyContactsFromListRequest(listId, {
        membership_ids,
        reason,
      }),
    onSuccess: () => {
      toast.success("Contacts have been disqualified from the list.");

      queryClient.invalidateQueries({
        queryKey: getListContactsQueryKey(listId),
      });
    },
    onError: () => toast.error("Failed to disqualify contacts from the list."),
  });
};

// Global search
export const useSearchContacts = (searchTerm?: string) => {
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 400);
  const api = useApiClient();

  return useInfiniteQuery({
    enabled: !!debouncedSearchTerm?.trim(),
    queryKey: [SEARCH_CONTACTS_QUERY_KEY, debouncedSearchTerm],
    queryFn: ({ pageParam }) =>
      api.searchContacts({
        next_token: pageParam,
        search_term: debouncedSearchTerm || "",
      }),
    initialPageParam: undefined as string | null | undefined,
    getNextPageParam: (lastPage) => lastPage.data?.next_token,
  });
};

export type SearchContactsApiI = ReturnType<typeof useSearchContacts>;
