import { AccountUserListI } from "@/interfaces/accounts";
import { ACCOUNT_LIST_TYPES } from "@/modules/pipeline/constants";
import { ListPillI } from "@/api/routes/list";

// Extracting that to a separate utility as that's reused across both lists view
// and account details. There's a custom behaviour for the Callback -> Nurture lists,
// which may potentially extend in the future.
export const getListName = (list?: AccountUserListI) => {
  if (!list) {
    return "N/A";
  }

  return list.list_type === ACCOUNT_LIST_TYPES.CALLBACKS
    ? "Nurture"
    : list.name;
};

export const checkIfCanAddLeadsToList = (list?: ListPillI) =>
  !list?.is_default_list && !list?.is_exclusive_list;
