import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";

import { LocalStorageSettingsI } from "@/interfaces/dialer/local-storage-settings";
import { CustomMeetingsToastI } from "@/interfaces/custom-toasts/meetings";

import { GetListsRequestFilterParamsI } from "@/api/routes/list";
import { CreateNewLeadFormI } from "@/modals/create-new-lead-modal/new-lead-form";

const AUTH0_TOKEN_UPDATED_AT = "auth0_token_updated_at";
const CUSTOM_MEETINGS_TOASTS = "custom_meetings_toasts";
const CALLING_MODULE_SETTINGS = "calling_module_settings";
const DIALER_TOUR_COMPLETION_DATE = "dialer_tour_completion_date";
const MESSAGES_INTRO_MODAL_VISITED_DATE = "messages_intro_modal_visited_date";
const DILAER_VISITED_AT = "dialer_visited_at";
const CATCHUPS_WALKTHROUGH_COMPLETED = "catchups_walkthrough_completed";
const NOTIFICATIONS_INTRO_MODAL_VISITED_DATE =
  "notifications_intro_modal_visited_date";

const ACCOUNT_DETAILS_BACK_TO = "account_details_back_to";
const ACTIVE_LIST_ID = "active_list_id";

const DIALER_GLOBAL_MAX_LEADS_DIALING = "dialer_global_max_leads_dialing";

const LISTS_TABLE_COLUMNS_VISIBILITY = "lists_table_columns_visibility";
const LISTS_INTRO_MODAL_VISITED_DATE = "lists_intro_modal_visited_date";
const LIST_SUBSECTION_INTRO_COMPLETED_DATE =
  "list_subsection_intro_completed_date";

/**
 * Should follow up by list id
 */
const LIST_FILTERS = "list_filters";

const PERFORMANCE_DASHBOARD_TIME_RANGE_FILTER =
  "performance_dashboard_time_range_filter";

const PERFORMANCE_DASHBOARD_CAMPAIGN_FILTER =
  "performance_dashboard_campaign_filter";

const GOAL_TRACKER_SHOWN_AFTER_FIRST_CALL =
  "goal_tracker_shown_after_first_call";

const NURTURING_INTRO_MODAL_VISITED_DATE = "nurturing_intro_modal_visited_date";

const ADD_NEW_LEAD_MODAL_PERSISTED_VALUES = "add_new_lead_modal";

const SEND_EMAIL_MODAL_PERSISTED_CONTENT = "email_modal_persisted_content";

// Works ONLY in the browser
export class LocalStorage {
  get auth0TokenUpdatedAt(): string {
    return window.localStorage.getItem(AUTH0_TOKEN_UPDATED_AT) as string;
  }
  set auth0TokenUpdatedAt(date: string) {
    window.localStorage.setItem(AUTH0_TOKEN_UPDATED_AT, date);
  }

  get customMeetingsToasts(): any | undefined {
    const storedToasts = window.localStorage.getItem(CUSTOM_MEETINGS_TOASTS);
    let parsedStoredToasts;

    try {
      parsedStoredToasts = JSON.parse(storedToasts as string);
    } catch {
      parsedStoredToasts = [];
    }

    return parsedStoredToasts;
  }
  set customMeetingsToasts(customMeetingToasts: CustomMeetingsToastI[]) {
    const uniqueCustomMeetingToasts = uniqWith(
      customMeetingToasts || [],
      isEqual
    );

    window.localStorage.setItem(
      CUSTOM_MEETINGS_TOASTS,
      JSON.stringify(uniqueCustomMeetingToasts)
    );
  }

  get callingModuleSettings(): LocalStorageSettingsI {
    const settings = window.localStorage.getItem(CALLING_MODULE_SETTINGS) || "";
    let parsedSettings;

    try {
      parsedSettings = JSON.parse(settings as string);
    } catch {
      parsedSettings = {};
    }

    return parsedSettings;
  }
  set callingModuleSettings(settings: LocalStorageSettingsI) {
    window.localStorage.setItem(
      CALLING_MODULE_SETTINGS,
      JSON.stringify(settings)
    );
  }

  get dialerTourCompletionDate(): string {
    return window.localStorage.getItem(DIALER_TOUR_COMPLETION_DATE) as string;
  }
  set dialerTourCompletionDate(date: string) {
    window.localStorage.setItem(DIALER_TOUR_COMPLETION_DATE, date);
  }

  get messagesIntroModalCompletionDate(): string {
    return window.localStorage.getItem(
      MESSAGES_INTRO_MODAL_VISITED_DATE
    ) as string;
  }
  set messagesIntroModalCompletionDate(date: string) {
    window.localStorage.setItem(MESSAGES_INTRO_MODAL_VISITED_DATE, date);
  }

  get notificationIntroModalCompletionDate(): string {
    return window.localStorage.getItem(
      NOTIFICATIONS_INTRO_MODAL_VISITED_DATE
    ) as string;
  }
  set notificationIntroModalCompletionDate(date: string) {
    window.localStorage.setItem(NOTIFICATIONS_INTRO_MODAL_VISITED_DATE, date);
  }

  get dialerVisitedAt(): string {
    return window.localStorage.getItem(DILAER_VISITED_AT) as string;
  }
  set dialerVisitedAt(date: string) {
    window.localStorage.setItem(DILAER_VISITED_AT, date);
  }

  get isCatchupsWalkthroughCompleted(): boolean {
    return window.localStorage.getItem(CATCHUPS_WALKTHROUGH_COMPLETED) !== null;
  }
  set isCatchupsWalkthroughCompleted(isCompleted: boolean) {
    if (isCompleted) {
      window.localStorage.setItem(CATCHUPS_WALKTHROUGH_COMPLETED, "true");
    } else {
      window.localStorage.removeItem(CATCHUPS_WALKTHROUGH_COMPLETED);
    }
  }

  get listsTableColumnsVisibility(): Record<string, boolean> | null {
    const storedColumns = window.localStorage.getItem(
      LISTS_TABLE_COLUMNS_VISIBILITY
    );
    let parsedColumns;

    try {
      parsedColumns = JSON.parse(storedColumns as string);
    } catch {
      parsedColumns = [];
    }

    return parsedColumns;
  }
  set listsTableColumnsVisibility(columns: Record<string, boolean> | null) {
    if (columns) {
      window.localStorage.setItem(
        LISTS_TABLE_COLUMNS_VISIBILITY,
        JSON.stringify(columns)
      );
    } else {
      window.localStorage.removeItem(LISTS_TABLE_COLUMNS_VISIBILITY);
    }
  }

  get listsIntroModalVisitedDate(): string {
    return window.localStorage.getItem(
      LISTS_INTRO_MODAL_VISITED_DATE
    ) as string;
  }
  set listsIntroModalVisitedDate(date: string) {
    window.localStorage.setItem(LISTS_INTRO_MODAL_VISITED_DATE, date);
  }

  get accountDetailsBackTo(): string {
    return window.localStorage.getItem(ACCOUNT_DETAILS_BACK_TO) as string;
  }
  set accountDetailsBackTo(backTo: string | null | undefined) {
    if (backTo) {
      window.localStorage.setItem(ACCOUNT_DETAILS_BACK_TO, backTo);
    } else {
      window.localStorage.removeItem(ACCOUNT_DETAILS_BACK_TO);
    }
  }

  get activeListId(): string {
    return window.localStorage.getItem(ACTIVE_LIST_ID) as string;
  }
  set activeListId(listId: string | null | undefined) {
    if (listId) {
      window.localStorage.setItem(ACTIVE_LIST_ID, listId);
    } else {
      window.localStorage.removeItem(ACTIVE_LIST_ID);
    }
  }

  get dialerGlobalMaxLeadsDialing(): number | undefined {
    return (
      parseInt(
        window.localStorage.getItem(DIALER_GLOBAL_MAX_LEADS_DIALING) || ""
      ) || undefined
    );
  }
  set dialerGlobalMaxLeadsDialing(value: number) {
    window.localStorage.setItem(
      DIALER_GLOBAL_MAX_LEADS_DIALING,
      value.toString()
    );
  }

  getListFilters(listId: string): GetListsRequestFilterParamsI {
    try {
      return JSON.parse(
        window.localStorage.getItem(`${LIST_FILTERS}-${listId}`) as string
      );
    } catch {
      return {};
    }
  }

  setListFilters(listId: string, filters?: GetListsRequestFilterParamsI): void {
    window.localStorage.setItem(
      `${LIST_FILTERS}-${listId}`,
      JSON.stringify(filters || {})
    );
  }

  resetAllListFilters() {
    Object.keys(window.localStorage)?.forEach((key) => {
      if (key.startsWith(LIST_FILTERS)) {
        window.localStorage.removeItem(key);
      }
    });
  }

  get performanceDashboardTimeRangeFilter(): string {
    return window.localStorage.getItem(
      PERFORMANCE_DASHBOARD_TIME_RANGE_FILTER
    ) as string;
  }
  set performanceDashboardTimeRangeFilter(filter: string) {
    window.localStorage.setItem(
      PERFORMANCE_DASHBOARD_TIME_RANGE_FILTER,
      filter
    );
  }

  get performanceDashboardCampaignFilter(): string {
    return window.localStorage.getItem(
      PERFORMANCE_DASHBOARD_CAMPAIGN_FILTER
    ) as string;
  }
  set performanceDashboardCampaignFilter(filter: string) {
    window.localStorage.setItem(PERFORMANCE_DASHBOARD_CAMPAIGN_FILTER, filter);
  }

  get goalTrackerShownAfterFirstCall(): string {
    return window.localStorage.getItem(
      GOAL_TRACKER_SHOWN_AFTER_FIRST_CALL
    ) as string;
  }
  set goalTrackerShownAfterFirstCall(value: string) {
    window.localStorage.setItem(GOAL_TRACKER_SHOWN_AFTER_FIRST_CALL, value);
  }

  get nurturingIntroModalVisitedDate(): string | null {
    return window.localStorage.getItem(NURTURING_INTRO_MODAL_VISITED_DATE);
  }
  set nurturingIntroModalVisitedDate(date: string) {
    window.localStorage.setItem(NURTURING_INTRO_MODAL_VISITED_DATE, date);
  }

  get listSubsectionIntroCompletedAt(): string | null {
    return window.localStorage.getItem(LIST_SUBSECTION_INTRO_COMPLETED_DATE);
  }
  set listSubsectionIntroCompletedAt(date: string) {
    window.localStorage.setItem(LIST_SUBSECTION_INTRO_COMPLETED_DATE, date);
  }

  get newLeadModalPersistedValues(): Partial<CreateNewLeadFormI> {
    const storedValues = window.localStorage.getItem(
      ADD_NEW_LEAD_MODAL_PERSISTED_VALUES
    );
    let parsedValues;

    try {
      parsedValues = JSON.parse(storedValues as string);
    } catch {
      parsedValues = {};
    }

    return parsedValues;
  }
  set newLeadModalPersistedValues(
    values: Partial<CreateNewLeadFormI> | null | undefined
  ) {
    if (!values) {
      window.localStorage.removeItem(ADD_NEW_LEAD_MODAL_PERSISTED_VALUES);
      return;
    }

    window.localStorage.setItem(
      ADD_NEW_LEAD_MODAL_PERSISTED_VALUES,
      JSON.stringify(values)
    );
  }

  set emailModalContent(content: string | null | undefined) {
    if (!content) {
      window.localStorage.removeItem(SEND_EMAIL_MODAL_PERSISTED_CONTENT);
      return;
    }

    window.localStorage.setItem(SEND_EMAIL_MODAL_PERSISTED_CONTENT, content);
  }
  get emailModalContent(): string {
    return window.localStorage.getItem(
      SEND_EMAIL_MODAL_PERSISTED_CONTENT
    ) as string;
  }
}
