import { FC, ReactNode } from "react";
import _isFunction from "lodash/isFunction";
import _noop from "lodash/noop";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";

import useFloatingPosition from "shared/lib/hooks/use-floating-position";
import ControlledDropdown from "shared/ui/controlled-dropdown";
import { clsxMerge } from "shared/lib/helpers";

interface ActionMenuActionBaseI {
  icon?: ReactNode;
  title: string | (() => string);
}

interface ActionMenuActionWithHrefI extends ActionMenuActionBaseI {
  href: string;
}

interface ActionMenuActionWithHandlerI extends ActionMenuActionBaseI {
  handler: () => void;
}

// Either href or handler is required, so use union type
export type ActionMenuActionI =
  | ActionMenuActionWithHrefI
  | ActionMenuActionWithHandlerI;

interface ActionMenuPropsI {
  className?: string;
  menuClassName?: string;
  itemClassName?: string;
  trigger?: ReactNode;
  triggerClassName?: string;
  actions?: ActionMenuActionI[];
}

// TODO: VLAD: Extract to a shared component when we first have it in other project
export const ActionMenu: FC<ActionMenuPropsI> = ({
  className,
  menuClassName,
  itemClassName,
  triggerClassName,
  trigger,
  actions = [],
}) => {
  const { refs, floatingStyles, onToggleVisibility } = useFloatingPosition();

  if (actions.length === 0) {
    return null;
  }

  return (
    <ControlledDropdown
      className={className}
      onOpen={onToggleVisibility}
      onClose={onToggleVisibility}
    >
      {() => (
        <>
          <label
            ref={refs.setReference}
            tabIndex={0}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            data-no-dnd="true"
          >
            {trigger ? (
              <div className={triggerClassName}>{trigger}</div>
            ) : (
              <div className="block cursor-pointer rounded border-none px-1 hover:bg-black/5">
                <EllipsisHorizontalIcon className="w-6" />
              </div>
            )}
          </label>

          <div
            className="dropdown-content menu"
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            data-no-dnd="true"
          >
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              tabIndex={0}
              className={clsxMerge(
                "transition-transform",
                "gap-1 rounded bg-base-100 p-2",
                "shadow-[4px_4px_20px_0px_rgba(0,0,0,0.10)]",
                menuClassName
              )}
            >
              {actions.map((action, idx) => (
                <div
                  key={idx}
                  onClick={"handler" in action ? action.handler : _noop}
                  className={clsxMerge(
                    "flex cursor-pointer rounded p-2",
                    "hover:bg-black/5 active:bg-black/10 active:text-black",
                    itemClassName
                  )}
                >
                  {action.icon}

                  {"handler" in action && (
                    <span className="ae-typography-body1">
                      {_isFunction(action.title)
                        ? action.title()
                        : action.title}
                    </span>
                  )}

                  {"href" in action && (
                    <a
                      className="ae-typography-body1"
                      href={action.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {_isFunction(action.title)
                        ? action.title()
                        : action.title}
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </ControlledDropdown>
  );
};
