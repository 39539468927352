import { CUSTOM_EVENTS } from "@/constants/custom-events";

import { ListMemberUpdateMessageI } from "@/interfaces/list-dialing";
import { EventHandler, useEffect, useState } from "react";

export type CallsMapI = Map<string, ListMemberUpdateMessageI>;

interface UseWSListDialingI {
  onIncomingCallStatusUpdate?: (data: ListMemberUpdateMessageI) => void;
}

export const useWSListDialing = ({
  onIncomingCallStatusUpdate,
}: UseWSListDialingI = {}) => {
  const [isListenWS, setIsListenWS] = useState(false);

  const [calls, setCalls] = useState<CallsMapI>(new Map());

  const handleIncomingCallStatusUpdate = (
    data: CustomEvent<ListMemberUpdateMessageI>
  ) => {
    const dialingStateData = data.detail;

    console.log(dialingStateData.status, dialingStateData);

    /**
     * Add or update the call status in the calls map
     * */
    setCalls((prevCalls) => {
      const newCalls = new Map(prevCalls);
      newCalls.set(dialingStateData.membership_id, dialingStateData);
      return newCalls;
    });

    onIncomingCallStatusUpdate?.(dialingStateData);
  };

  const clear = () => {
    setCalls(new Map());
  };

  useEffect(() => {
    if (isListenWS)
      window.document.addEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.CALL_STATUS_UPDATE,
        handleIncomingCallStatusUpdate as EventHandler<any>
      );
    else
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.CALL_STATUS_UPDATE,
        handleIncomingCallStatusUpdate as EventHandler<any>
      );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.CALL_STATUS_UPDATE,
        handleIncomingCallStatusUpdate as EventHandler<any>
      );
    };
  }, [isListenWS]);

  return {
    calls,
    setCalls,
    clear,

    isListenWS,
    setIsListenWS,
  };
};
